<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width=" ">
        <el-form-item label="操作人">
          <el-input style="width:250px" v-model="searchData.userName" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="操作日期">
          <el-date-picker
            v-model="searchData.timeSlot"
            style="width:300px"
            size="small"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { getListAPI } from './api'
const columns = [
  {
    label: '操作人',
    prop: 'user',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['user']['userName']}</p>
    }
  },
  {
    label: '操作内容',
    prop: 'subject',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['subject']}</p>
    }
  },
  {
    label: 'IP',
    prop: 'ip',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['ip']}</p>
    }
  },
  {
    label: '操作时间',
    prop: 'createTime',
    minWidth: '160',
    fixed: 'right',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  }
]
export default {
  name: 'List',

  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        userName: '',
        leftCreateDate: '',
        rightCreateDate: '',
        timeSlot: []
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { userName, timeSlot, leftCreateDate, rightCreateDate } = this.searchData
      let params = { userName, page: this.currentPage, pageSize: this.pageSize }

      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0] || ''
        params.rightCreateDate = timeSlot[1] || ''
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form /deep/ {
    .el-form-item {
      margin-right: 35px;
      .el-date-editor {
        .el-range-separator {
          padding: 0;
        }
      }
    }
  }
}
</style>
